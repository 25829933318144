import { useUserStore } from '@/stores/userStore'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()

  if (process.server && nuxtApp.$authTokenStatus === 'expired_token') return

  const userStore = await useUserStore()

  if (!userStore.isAuthenticated) {
    const url = useRequestURL()
    const redirectUrl = `${url.origin}${to.fullPath}`

    return await userStore.signIn({ redirectUrl })
  }
})
